body
{
  background-color: #ffffff;
}

body::-webkit-scrollbar 
{
    width: 0.5rem;
    display: none;
}
   
body::-webkit-scrollbar-track 
{
    display: none;
    background-color: white;
}
   
body::-webkit-scrollbar-thumb 
{
    background-color: rgb(47, 46, 65);
    border-radius: 50px;
}

a
{
    text-decoration: none;
    color: #2F2E41;
}

.download:hover a
{
    text-decoration: none;
    color: #FF6366;
}

h1
{
    color: #FF6366;
    text-align: center;
    font-size: 90px;
    font-weight: bold;
    padding: 0px 80px 10px 80px;
    background-color: white;
    border-radius: 100px;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.35);
    margin-top: 25px;
}

h2
{
    font-size: 1.5rem;
    margin: 0;
    color: #2F2E41;
    text-align: center;
    padding: 0px 20px 0px 20px;
}

.red
{
    color: #FF6366;
}

.download
{
    padding: 10px;
    font-weight: bold;
    font-size: 18px;
    background-color: #2F2E41;
    border-radius: 20px;
    width: 120px;
    margin: 40px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    color:  #FF6366;
    border: none;
    text-decoration: none;
    outline:none!important;
}

.download:hover
{
    background-color: #2F2E41;
    color: #FF6366;
}


.download2
{
    padding: 10px;
    font-weight: bold;
    font-size: 18px;
    background-color: #FF6366;
    border-radius: 20px;
    width: 120px;
    margin: 40px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    color:  #2F2E41;
    border: none;
    text-decoration: none;
    outline:none!important;
}

.download2:hover
{
    background-color: #2F2E41;
    color: #FF6366;
}

.download2:hover a
{
    background-color: #2F2E41;
    color: #FF6366;
}

img
{
    width: 100%;
}

.card
{
    background-color: white;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.35);
    border-radius: 20px;
    padding: 10px;
    width: 100%;
}

.cardNotification
{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
}

div.item {
    /* To correctly align image, regardless of content height: */
    vertical-align: top;
    display: inline-block;
    /* To horizontally center images and caption */
    text-align: center;
    /* The width of the container also implies margin around the images. */
    width: 20%;
    padding: 20px;
}

.caption {
    /* Make the caption a block so it occupies its own line. */
    display: block;
    text-align: center;
    padding: 40px;
    color: #2F2E41;
    font-size: 20px;
    font-weight: bold;
}


.vertical-timeline::before
{
    background-color: #2F2E41!important;
    height: 60%!important;
    top: 50px!important;
}


.vertical-timeline-element-icon
{
    box-shadow: none!important;
}


.bmc-button img {
    height: 34px !important;
    width: 35px !important;
    margin-bottom: 1px !important;
    box-shadow: none !important;
    border: none !important;
    vertical-align: middle !important;
    }

    .bmc-button {
    padding: 7px 10px 7px 10px !important;
    line-height: 35px !important;
    height: 51px !important;
    min-width: 217px !important;
    text-decoration: none !important;
    display: inline-flex !important;
    color: #ffffff !important;
    background-color: #FF5F5F !important;
    border-radius: 5px !important;

    padding: 7px 10px 7px 10px !important;
    font-size: 22px !important;
    letter-spacing: 0.6px !important;

    margin: 0 auto !important;
    font-family: 'Cookie', cursive !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    -o-transition: 0.3s all linear !important;
    -webkit-transition: 0.3s all linear !important;
    -moz-transition: 0.3s all linear !important;
    -ms-transition: 0.3s all linear !important;
    transition: 0.3s all linear !important;
    }

    .bmc-button:hover,
    .bmc-button:active,
    .bmc-button:focus {
    -webkit-box-shadow: 0px 1px 2px 2px white !important;
    text-decoration: none !important;
    box-shadow: 0px 0px 0px 3px white !important;
    opacity: 0.85 !important;
    color: #ffffff !important;
    }



    .Left
    {
    left: -400px;
    position: relative;
    animation: slide 2s forwards;
    }

    .Right
    {
        left: 400px;
        position: relative;
        animation: slide 2s forwards;
    }


      @keyframes slide {
    100% { left: 0; }
}



.agb
{
    color: white;
    text-align: center;
    margin: 40px 20px 0px 20px;

}


.CollapsibleWrapper
{
    display: flex;
    justify-content: center;
}



/*Page: Fragen Antworten*/
    .Collapsible
    {
        margin: 10px 450px 10px 450px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.35);
        border-radius: 20px 20px 20px 20px;
        
    }

    .Collapsible:hover
    {
        background-color:rgb(47, 46, 65);
        color: white;
    }

    .Collapsible__trigger
    {
        background-color: white;
        text-align: center;
        margin: 15px;
        font-weight: bold;
        font-size: 18px;
        pointer-events: stroke;
        cursor: pointer;
    }

    .Collapsible__contentOuter
    {
        border-radius: 0px 0px 20px 20px;
    }


    .Collapsible:hover .Collapsible__contentInner
    {
        color: white;
    }

    .Collapsible__contentInner
    {
        text-align: justify;
        color: black;
        padding: 10px 20px 10px 20px;
        border-radius: 0px 0px 20px 20px;
        font-size: 18px;
    }
    
    /*Page: Fragen Antworten*/

@media only screen and (max-width: 991px)/*Mobile Ansicht*/
{
    .Collapsible
    {
        margin: 10px 20px 10px 20px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.35);
        border-radius: 20px 20px 20px 20px;
        
    }
}

